import React from 'react';

const Features = () => {

    const isLargeScreen = window.matchMedia("(min-width: 960px)").matches;

    return (
        <div id="features" className="bg-slate-900 py-28" >
            <section>
                <div className="my-4 py-4" data-aos="fade-up">
                    <h2 className="my-2 text-center text-3xl text-slate-50 uppercase font-bold">features</h2>

                    <div className='flex justify-center'>
                        <div className='w-24 border-b-4 border-slate-50'></div>
                    </div>
                </div>

                <div className="lg:px-56 md:px-28" data-aos-delay="600">
                    <div className="grid grid-cols-1 gap-5">

                        <div className="transition-all ease-in-out duration-400 overflow-hidden text-gray-700 p-3 group" data-aos="fade-up">
                            <div className="m-2 text-justify text-sm rounded-lg flex flex-col md:flex-row items-center justify-center sm:gap-4 lg:gap-20">
                                <video className="2xl:w-1/4 md:w-1/3 w-1/2 aspect-ratio-6/13 rounded-lg" autoPlay={isLargeScreen ? true: false} controls={isLargeScreen ? false: true} muted loop src='https://ik.imagekit.io/poonr2gma/Screen%20Recording%202023-09-14%20at%208.02.18%20PM.mp4?updatedAt=1694744402813' />
                                <div className='relative'>
                                    <p className='order-font text text-white opacity-10 font-bold absolute top-(-1/3) left-0 transform -translate-y-1/2'>1</p>
                                    <h2 className="font-bold my-4 text-3xl text-slate-50 text-center md:text-left">All-in-one Homepage</h2>
                                    <p className="text-lg text-slate-200 text-center md:text-left">
                                        Through EasyMed's homepage, users can effortlessly check for drug-drug and drug-food interactions, access curated daily articles on polypharmacy, and access personalized pill reminders.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="transition-all ease-in-out duration-400  overflow-hidden text-gray-700 p-3 group " data-aos="fade-up">
                            <div className="m-2 text-justify text-sm rounded-lg flex flex-col md:flex-row-reverse items-center sm:gap-4 lg:gap-20">
                                <video className="2xl:w-1/4 md:w-1/3 w-1/2 aspect-ratio-6/13 rounded-lg" autoPlay={isLargeScreen ? true: false} controls={isLargeScreen ? false: true} muted loop src='https://ik.imagekit.io/poonr2gma/Screen%20Recording%202023-09-14%20at%208.03.09%20PM.mp4?updatedAt=1694744406528' />
                                <div className='relative'>
                                    <p className='order-font text text-white opacity-10 font-bold absolute top-(-1/3) left-0 transform -translate-y-1/2'>2</p>
                                    <h2 className="font-bold my-4 text-3xl text-slate-50 text-center md:text-left">Check Drug-to-drug Interaction</h2>
                                    <p className="text-lg text-slate-200 text-center md:text-left">
                                        Effortlessly search or scan a drug by barcode to check interaction details with meds in your list, stay updated on side effects and food interactions, and add to list for future reference.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="transition-all ease-in-out duration-400 overflow-hidden text-gray-700 p-3 group" data-aos="fade-up">
                            <div className="m-2 text-justify text-sm rounded-lg flex flex-col md:flex-row items-center justify-center sm:gap-4 lg:gap-20">
                                <video className="2xl:w-1/4 md:w-1/3 w-1/2 aspect-ratio-6/13 rounded-lg" autoPlay={isLargeScreen ? true: false} controls={isLargeScreen ? false: true} muted loop src='https://ik.imagekit.io/poonr2gma/Screen%20Recording%202023-09-14%20at%208.04.05%20PM.mp4?updatedAt=1694744406363' />
                                <div className='relative'>
                                    <p className='order-font text text-white opacity-10 font-bold absolute top-(-1/3) left-0 transform -translate-y-1/2'>3</p>
                                    <h2 className="font-bold my-4 text-3xl text-slate-50 text-center md:text-left">Check Drug-to-food Interaction</h2>
                                    <p className="text-lg text-slate-200 text-center md:text-left">
                                        Just snap a photo of your food items or product barcode, and the app performs a thorough analysis, alerting you of any potential adverse interactions with your list of medications.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="transition-all ease-in-out duration-400  overflow-hidden text-gray-700 p-3 group " data-aos="fade-up">
                            <div className="m-2 text-justify text-sm rounded-lg flex flex-col md:flex-row-reverse items-center sm:gap-4 lg:gap-20">
                                <video className="2xl:w-1/4 md:w-1/3 w-1/2 aspect-ratio-6/13 rounded-lg" autoPlay={isLargeScreen ? true: false} controls={isLargeScreen ? false: true} muted loop src='https://ik.imagekit.io/poonr2gma/Screen%20Recording%202024-02-25%20at%202(1).55?updatedAt=1708898358590' />
                                <div className='relative'>
                                    <p className='order-font text text-white opacity-10 font-bold absolute top-(-1/3) left-0 transform -translate-y-1/2'>4</p>
                                    <h2 className="font-bold my-4 text-3xl text-slate-50 text-center md:text-left">My Drug List & Drug Reminders</h2>
                                    <p className="text-lg text-slate-200 text-center md:text-left">
                                        Keep a detailed drug list for future reference during doctor visits, and receive customized medication reminders to ensure timely and accurate dosing.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="transition-all ease-in-out duration-400 overflow-hidden text-gray-700 p-3 group" data-aos="fade-up">
                            <div className="m-2 text-justify text-sm rounded-lg flex flex-col md:flex-row items-center justify-center sm:gap-4 lg:gap-20">
                                <video className="2xl:w-1/4 md:w-1/3 w-1/2 aspect-ratio-6/13 rounded-lg" autoPlay={isLargeScreen ? true: false} controls={isLargeScreen ? false: true} muted loop src='https://ik.imagekit.io/poonr2gma/explore.mp4?updatedAt=1708898315693' />
                                <div className='relative'>
                                    <p className='order-font text text-white opacity-10 font-bold absolute top-(-1/3) left-0 transform -translate-y-1/2'>5</p>
                                    <h2 className="font-bold my-4 text-3xl text-slate-50 text-center md:text-left">Explore</h2>
                                    <p className="text-lg text-slate-200 text-center md:text-left">
                                        Access personalized articles to enhance your understanding of polypharmacy, with the option to filter articles by category and enjoy interactive quizzes for added engagement and learning.
                                    </p>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </section>

            {/* <section>
                <div className="m-auto max-w-6xl p-2 md:p-20 h-5/6">
                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="zoom-out">
                        <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
                            <div className='text-blue-700 mb-4'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 24 24" className='fill-current'><path d="M2 12h2a7.986 7.986 0 0 1 2.337-5.663 7.91 7.91 0 0 1 2.542-1.71 8.12 8.12 0 0 1 6.13-.041A2.488 2.488 0 0 0 17.5 7C18.886 7 20 5.886 20 4.5S18.886 2 17.5 2c-.689 0-1.312.276-1.763.725-2.431-.973-5.223-.958-7.635.059a9.928 9.928 0 0 0-3.18 2.139 9.92 9.92 0 0 0-2.14 3.179A10.005 10.005 0 0 0 2 12zm17.373 3.122c-.401.952-.977 1.808-1.71 2.541s-1.589 1.309-2.542 1.71a8.12 8.12 0 0 1-6.13.041A2.488 2.488 0 0 0 6.5 17C5.114 17 4 18.114 4 19.5S5.114 22 6.5 22c.689 0 1.312-.276 1.763-.725A9.965 9.965 0 0 0 12 22a9.983 9.983 0 0 0 9.217-6.102A9.992 9.992 0 0 0 22 12h-2a7.993 7.993 0 0 1-.627 3.122z"></path><path d="M12 7.462c-2.502 0-4.538 2.036-4.538 4.538S9.498 16.538 12 16.538s4.538-2.036 4.538-4.538S14.502 7.462 12 7.462zm0 7.076c-1.399 0-2.538-1.139-2.538-2.538S10.601 9.462 12 9.462s2.538 1.139 2.538 2.538-1.139 2.538-2.538 2.538z"></path></svg>
                            </div>
                            <h3 className="text-3xl  text-blue-700 
                            font-bold">We <span className='font-black'>Build</span></h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>
                                    With over 10 years experience in software analysis and design and a deep understanding of the latest IT trends and solutions, we provide customized recommendations and strategies to help you improve your operations, reduce costs, and increase efficiency.
                                </p>
                            </div>
                        </div>
                        <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
                            <div className='text-blue-700 mb-4'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 24 24" className='fill-current'><path d="m7.375 16.781 1.25-1.562L4.601 12l4.024-3.219-1.25-1.562-5 4a1 1 0 0 0 0 1.562l5 4zm9.25-9.562-1.25 1.562L19.399 12l-4.024 3.219 1.25 1.562 5-4a1 1 0 0 0 0-1.562l-5-4zm-1.649-4.003-4 18-1.953-.434 4-18z"></path></svg>
                            </div>
                            <h3 className="text-3xl  text-blue-700 font-bold">We <span className='font-black'>Collaborate</span></h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>We can collaborate with your existing tech team to scale existing software applications or design customized software applications that suits your everyday need and simplifies various processes.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
        </div>
    )
}

export default Features;