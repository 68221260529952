import React, { useEffect } from 'react';
import AOS from 'aos';
import "aos/dist/aos.css";
import './index.css';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';
// All pages
import Home from './pages/Home';
import Contact from './pages/Contact';
import DemoProduct from './pages/DemoProduct';

import { useDocTitle } from './components/CustomHook';
import ScrollToTop from './components/ScrollToTop';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDApTpueWvC-8VEg5mmDziWlUJVZzcsfLI",
  authDomain: "easymed-26a3f.firebaseapp.com",
  projectId: "easymed-26a3f",
  storageBucket: "easymed-26a3f.appspot.com",
  messagingSenderId: "859072358561",
  appId: "1:859072358561:web:77167289e8c4bb8cb9e8e0",
  measurementId: "G-ELXNF1J1TC"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {
  useEffect(() => {
    const aos_init = () => {
      AOS.init({
        once: true,
        duration: 1000,
        easing: 'ease-out-cubic',
      });
    }

    window.addEventListener('load', () => {
      aos_init();
    });
  }, []);

  useDocTitle("EasyMed");

  return (
    <>
      <Router>
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/get-demo" element={<Contact />} />
          </Routes>
        </ScrollToTop>
      </Router>
    </>
  );
}


export default App;
