import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import logo from '../images/logo.png';

const Footer = () => {
    return (
        <>
            <footer>
                <div className="footer max-w-full mx-auto px-4 lg:px-24 px-12 bg-gray-100 border-t border-b py-30">

                    {/* Top area: Blocks */}
                    <div className="grid sm:grid-cols-12 gap-5 py-8 md:py-12 border-t border-gray-200 lg:ml-11 items-center">

                        {/* 1st block */}
                        <div className="col-span-6">
                            <div className='flex gap-4 items-center'>
                                <img className="w-16 h-16 object-cover" src={logo} />
                                <p className='font-medium'>Ensure Ease of Mind for Your Medical Management</p>
                            </div>
                        </div>

                        {/* 2nd block */}
                        <div className="col-span-6">
                            <div className="flex flex-wrap items-center md:justify-between justify-center">
                                <div className="w-full mx-auto text-right py-2">
                                    <div className="text-sm text-gray-400 font-semibold py-1">
                                        Copyright &copy; {new Date().getFullYear()}{"  "}
                                        <HashLink
                                            to="#"
                                            className=" hover:text-gray-900"
                                        >
                                            EasyMed
                                        </HashLink>. All rights reserved.
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>



                </div>

            </footer>
        </>
    )
}
export default Footer;
