import React from 'react';
import kws from '../images/clients/kws.png';
import geps from '../images/clients/geps.png';
import protergia from '../images/clients/protergia.png';
import reviewicon from '../images/review.svg';

const clientImage = {
    height: '10rem',
    width: 'auto',
    mixBlendMode: 'colorBurn'
}

const Reviews = () => {
    return (
        <div className="py-28 md:px-28 px-12 bg-grey-900">
            <section data-aos="fade-up">
                <div className="my-8">
                    <h2 className="my-2 text-center text-3xl text-blue-700 uppercase font-bold">Reviews</h2>
                    <div className='flex justify-center'>
                        <div className='w-24 border-b-4 border-blue-700'></div>
                    </div>
                </div>

                <div data-aos="fade-in" data-aos-delay="600">
                    <div className="grid sm:grid-cols-1 lg:grid-cols-3 gap-5">
                        <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max px-8 py-8">
                            <div className='flex gap-4 mb-4'>
                                <img className='rounded-full w-24 h-24 object-cover' src='https://ik.imagekit.io/poonr2gma/image_52_1_.png?updatedAt=1688442545129' />
                                <div className="text-justify text-sm flex-grow flex flex-col justify-center">
                                    <h4 className="font-semibold text-md md:text-2xl mb-2">Diane</h4>
                                    <p className="text-md h-auto text-gray-600">
                                        Retired, 76 years old
                                    </p>
                                </div>
                                <img alt="review img" className="float-right w-8 h-8" src={reviewicon} />
                            </div>
                            <p className="text-lg h-auto">
                                “I would definitely recommend this app to my friends. I find it really helpful in avoiding potential drug interactions and planning appropriate meals for daily life.”
                            </p>
                        </div>

                        <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max px-8 py-8">
                            <div className='flex gap-4 mb-4'>
                                <img className='rounded-full w-24 h-24 object-cover' src='https://ik.imagekit.io/poonr2gma/image_53.png?updatedAt=1688442526857' />
                                <div className="text-justify text-sm flex-grow flex flex-col justify-center">
                                    <h4 className="font-semibold text-md md:text-2xl mb-2">Ryan</h4>
                                    <p className="text-md h-auto text-gray-600">
                                        Physician, 45 years old
                                    </p>
                                </div>
                                <img alt="review img" className="float-right w-8 h-8" src={reviewicon} />
                            </div>
                            <p className="text-lg h-auto">
                                “I think this app is going to be a game-changer for us and our patients! It'll definitely make our work a lot easier and benefit our patients a ton.”
                            </p>
                        </div>

                        <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max px-8 py-8">
                            <div className='flex gap-4 mb-4'>
                                <img className='rounded-full w-24 h-24 object-cover' src='https://ik.imagekit.io/poonr2gma/image_51.png?updatedAt=1688442527056' />
                                <div className="text-justify text-sm flex-grow flex flex-col justify-center">
                                    <h4 className="font-semibold text-md md:text-2xl mb-2">Jim</h4>
                                    <p className="text-md h-auto text-gray-600">
                                        College student, 23 years old
                                    </p>
                                </div>
                                <img alt="review img" className="float-right w-8 h-8" src={reviewicon} />
                            </div>
                            <p className="text-lg h-auto">
                                “This app is incredible! I'm definitely going to learn a ton about adverse interactions. It'll come in handy when I have to juggle multiple medications.”
                            </p>
                        </div>

                    </div>
                </div>
            </section>
        </div>
    )
}

export default Reviews;