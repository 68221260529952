import React from 'react';
import { Link } from 'react-router-dom';

const Performance = () => {
    return (
        <>
            <div className="py-28 bg-slate-900" id='portfolio'>
                <h2 className="my-2 text-center text-3xl text-white uppercase font-bold">performance</h2>
                <div className='flex justify-center'>
                    <div className='w-24 border-b-4 border-white mb-8'></div>
                </div>

                <div className="px-2 md:px-4 lg:px-28" data-aos="fade-down" data-aos-delay="600">
                    <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5">
                        <div className="bg-gray-100 transition-all ease-in-out duration-400 overflow-hidden hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max">
                            <div className="text-justify text-sm py-12">
                                <h4 className="font-semibold text-md md:text-2xl text-center mb-8">Accuracy</h4>
                                <p className="text-6xl font-extrabold h-auto text-center text-blue-700">
                                    93%
                                </p>

                            </div>
                        </div>

                        <div className="bg-gray-100 transition-all ease-in-out duration-400 overflow-hidden hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max">
                            <div className="text-justify text-sm py-12">
                                <h4 className="font-semibold text-md md:text-2xl text-center mb-8">Drug-drug Pairs</h4>
                                <p className="text-6xl font-extrabold h-auto text-center text-blue-700">
                                    200k
                                </p>

                            </div>
                        </div>

                        <div className="bg-gray-100 transition-all ease-in-out duration-400 overflow-hidden hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max">
                            <div className="text-justify text-sm py-12">
                                <h4 className="font-semibold text-md md:text-2xl text-center mb-8">Food-drug Pairs</h4>
                                <p className="text-6xl font-extrabold h-auto text-center text-blue-700">
                                    330k
                                </p>

                            </div>
                        </div>


                    </div>
                    <div className="my-8 space-x-0 md:space-x-2 md:mb-8 flex justify-center">
                        <Link to="/contact" className="text-white bg-blue-700 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0">
                            Schedule a Demo
                            <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Performance;