import React from 'react';
import { Link } from 'react-router-dom';

const UseCase = () => {
    return (
        <div className='flex px-1 py-28 xl:px-56 gap-8 usecase' id='about'>

                <div className="flex flex-col-reverse lg:flex-row py-8 items-center lg:text-left" data-aos="fade-up">

                    <div className="flex-col my-4 text-center lg:text-left lg:justify-end items-center px-8" data-aos="zoom-in" data-aos-delay="500">

                        <div>
                            <h2 className="my-2 text-3xl text-white uppercase font-bold text-center">Why We Created Easymed?</h2>
                            <div className='flex justify-center'>
                                <div className='w-24 border-b-4 border-white mb-8'></div>
                            </div>
                        </div>

                        <div>
                            <p className='my-3 text-xl text-gray-200 font-medium text-center'>Did you know nearly 500 million older adults globally face significant polypharmacy risks, with a 50% higher chance of adverse drug interactions when using five or more medications? Additionally, they encounter over two potential drug-food interactions monthly, sometimes leading to disabilities and deaths.</p>
                        </div>

                        <div>
                            <p className='my-3 text-xl text-gray-200 font-medium text-center'>To address this critical concern, we developed EasyMed—an innovative interaction checker painstakingly designed to aid and educate the broader public, with a particular focus on senior adults. Its primary aim is to empower users to swiftly and accurately identify multidrug interactions with deep learning technology, mitigating potential consequences effectively. </p>
                        </div>
                    </div>
            </div>
        </div>
    )
}

export default UseCase;