import React from 'react';
import Reviews from '../components/Reviews';
import Cta from '../components/Cta';
import Footer from '../components/Footer';
import Hero from '../components/Hero';
import Technology from '../components/Technology';
import Performance from '../components/Performance';
import Features from '../components/Features';
import UseCase from '../components/UseCase';


const Home = () => {
    return (
        <>
            <Hero />
            <UseCase />
            <Features />
            <Technology />
            <Performance />
            <Reviews />
            <Cta/>
            <Footer />
        </>

    )
}

export default Home;

