import React from 'react';
import img from '../images/easymed-intro.svg';
import technology from '../images/websitetechnology.png';
import { Link } from 'react-router-dom';

const Technology = () => {
    return (
        <div className='relative bg-white'>
            <img src={technology} className='h-full absolute top-0 left-[-160px] 2xl:left-0 xl:block hidden object-cover' data-aos="fade-up" />
            <div className="m-auto p-1 lg:p-28 h-5/6" id='technology' >

                <div className="flex flex-col-reverse lg:flex-row py-8 xl:justify-end justify-center items-center" data-aos="fade-up">

                    <div className="flex-col my-4 text-center lg:text-left items-center w-full xl:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">

                        <div>
                            <h2 className="my-2 text-3xl text-blue-700 uppercase font-bold text-center xl:text-left">our technology</h2>
                            <div className='flex justify-center xl:justify-start'>
                                <div className='w-24 border-b-4 border-blue-700 mb-8'></div>
                            </div>
                        </div>

                        <div>
                            <p className='my-3 text-xl text-slate-700 font-medium text-center xl:text-left'>We developed DeepDDI, a computational framework using drug-drug and drug-food pairs' compound structural information as inputs to generate 86 important drug-drug interaction (DDI) types as human-readable sentences.</p>
                        </div>

                        <div>
                            <p className='my-3 text-xl text-slate-700 font-medium text-center xl:text-left'>Leveraging cutting-edge AI technologies, we also offer users accessible features including drug and food recognition, text-to-speech functionalities, and clear explanations for complex medical terminology.</p>
                        </div>
                        <div className="space-x-0 flex justify-center xl:justify-start">
                            <Link to="/contact" className="text-white bg-blue-700 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-2 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0 group">
                                Get More Information
                                <svg className="w-4 h-4 ml-1 group-hover: translate-x-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                            </Link>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Technology;